<template lang="pug">
.nav-placeholder(v-if="isDesktop")
.nav-placeholder-mobile(v-else)
</template>

<script setup lang="ts">
const { isDesktop } = useDevice()
</script>

<style scoped lang="sass">
.nav-placeholder
	height: 105px
	width: 100%
.nav-placeholder-mobile
	width: 100%
	min-height: 202px
</style>
